import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the shape of your context
interface SidebarContextType {
  expandedMenus: any
  setExpandedMenus: any
}

// Create the context with a default value
const SidebarContext = createContext<SidebarContextType | undefined>(undefined)

// Create the provider component
const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [expandedMenus, setExpandedMenus] = useState<any>(['dashboard'])
  return <SidebarContext.Provider value={{ expandedMenus, setExpandedMenus }}>{children}</SidebarContext.Provider>
}

// Custom hook for using the ThemeContext
const useSidebar = () => {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }
  return context
}

export { SidebarProvider, useSidebar }
