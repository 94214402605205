/*
 * Reusable helper functions
 */

import { KeyboardEvent } from 'react'
import moment from 'moment'
import toast from 'react-hot-toast'
import { ColumnFilters, TableFilters } from './schema/TableColumn'
import { success } from '../api/statusCodes'

// Local storage stuff start
export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value)
}

export const getLocalStorage = (key: string) => {
  if (localStorage.getItem(key)) {
    return localStorage.getItem(key)
  }
  return null
}

export const clearLocalStorage = () => {
  // localStorage.removeItem('refresh_token')
  // localStorage.removeItem('access_token')
  // localStorage.removeItem('user_info')
  // localStorage.removeItem('user_info')
  // localStorage.removeItem('permissions')
  localStorage.clear()
}
// Local storage stuff end

// Authorizations
export const PermittedRoutes = (sidebarLinks: any) => {
  // @TODO: permission part remaining from backend
  const permissions = JSON.parse(`${getLocalStorage('permissions')}`)
  const byPassRoutes = ['copilots']
  const filteredLinks = sidebarLinks?.filter((link: any) => byPassRoutes?.includes(link.slug)|| permissions?.some((permission: any) => permission.slug === link.slug))

  return filteredLinks
  // return sidebarLinks
}

// Date
export const defaultDateFormat = 'DD/MM/YYYY'
export const formatDate = (date: string, format?: string) => {
  return moment(date).format(format || defaultDateFormat)
}

export const getMonths = (startDate?: any, endDate?: any) => {
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  
  const end = endDate ? new Date(endDate) : new Date(); 
  const start = startDate ? new Date(startDate) : new Date(end.getFullYear(), end.getMonth() - 11, 1);
  
  let result = [];
  
  for (let d = start; d <= end; d.setMonth(d.getMonth() + 1)) {
    result.push({
      month: months[d.getMonth()],
      year: d.getFullYear()
    });
  }
  
  return result;
}

export const tableFiltersUrl = (tableFilters: TableFilters) => {
  const { pagination, columnFilters, sorting } = tableFilters

  let url = ''
  // Pagination url
  url += `page_number=${pagination?.pageIndex}&page_size=${pagination?.pageSize}`
  // Filter url
  if (columnFilters && columnFilters?.length) {
    columnFilters?.map((column: ColumnFilters) => {
      url += `&${column.id}=${column.value}`
    })
  }
  // Sorting url
  if (sorting && sorting?.length) {
    url += `&sort_by=${sorting[0]?.id}&sort_type=${sorting[0]?.desc ? 'desc' : 'asc'}`
  }
  return url
}

// Order
export const orderByPosition = (data: any) => {
  return refLess(data)?.sort((a: any, b: any) => a?.position - b?.position)
}
export const convertToLabelValue = (data: any[], labelKey?: string, valueLey?: string) => {
  if (!data) return []
  return refLess(data)?.map((item: any) => {
    return {
      label: labelKey ? item[labelKey] : item?.name,
      value: valueLey ? item[valueLey] : item?.id,
      ...item,
    }
  })
}

// Reface
export const refLess = (data: any) => {
  if (data) {
    return JSON.parse(JSON.stringify(data))
  }
  return data
}

// String
export const trimString = (str: string | number | null | undefined) => {
  return (str ? `${str}` : '')?.trim()
}

export const truncateString = (input: string, len?: number): string => {
  const maxLength = len || 15;
  if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
  } else {
      return input;
  }
}

// Http
export const isSuccess = (response: any) => {
  return response?.status === success && response?.data?.meta?.code === success
}
export const showError = (error: any) => {  
  if (error) toast.error(error?.data?.meta?.message)
}

// Input
export const integerOnly = (event: KeyboardEvent<HTMLInputElement>) => {
  // Prevent entering '.' for floating-point numbers
  if (event.key === '.') {
    event.preventDefault();
  }
};

export function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;

  return function(...args: any) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const hasPermission = (modules: any, module:any, permission: any) => {
  return modules?.find((m:any) => m?.slug === module)?.permission_ids?.includes(permission)
}

export const roundOffNumber = (num: number) => {
  return Number.isInteger(num) ? num : parseFloat(num.toFixed(2))
}