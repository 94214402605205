import request from '../axiosInterceptor'

const api = {
  getStatisticsCapturesDashboard(query: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/dashboard/get-statistic-capture-report-list-dashboard${query}`,
      // url: `${process.env.REACT_APP_API_BASEURL}v1/dashboard/get-statistic-capture-report-list-dashboard?from_date${''}&to_date=${''}`,
      method: 'get',
    })
  },
  getStatisticsCaptures(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/statistics-capture/statistic-capture-schedule-list`,
      method: 'get',
    })
  },
  getStatisticsCaptureReport(id: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/statistics-capture/statistic-capture-report-list`,
      method: 'get',
    })
  },
  getExportReport(id: any, format: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/statistics-capture/statistic-capture-report-export?format=${format}`,
      method: 'get',
      responseType: format === 'xlsx' ? 'blob' : undefined,
    })
  },
  getExportObservations(id: any, format: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/observations/observations-list-export?format=${format}`,
      method: 'get',
      responseType: format === 'xlsx' ? 'blob' : undefined,
    })
  },
  postAddSchedule(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/statistics-capture/add-statistic-capture`,
      method: 'post',
      data: data,
    })
  },
  getScheduleDetails(id: any, scheduleId: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/statistics-capture/statistic-capture-schedule?schedule_id=${scheduleId}`,
      method: 'get',
    })
  },
  postEditSchedule(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/statistics-capture/edit-statistic-capture-schedule-details`,
      method: 'post',
      data: data,
    })
  },
  getStatisticsCaptureReportDetails(id: any, taskId: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/statistics-capture/get-statistic-capture-report?task_id=${taskId}`,
      method: 'get',
    })
  },
  postEditReport(id: any, data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/project-management/${id}/statistics-capture/add-statistic-capture-report`,
      method: 'post',
      data: data,
    })
  },
}

export default api
