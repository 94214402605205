import MasterModule from './modules/MasterModule'
import LoginModule from './modules/LoginModule'
import UserManagementModule from './modules/UserManagementModule'
import ProjectManagementModule from './modules/ProjectManagementModule'
import TaskManagementModule from './modules/TaskManagementModule'
import ThemeModule from './modules/ThemeModule'
import WorkspaceModule from './modules/WorkspaceModule'
import StatisticsCaptureModule from './modules/StatisticsCaptureModule'
import DashboardModule from './modules/DashboardModule'

const api = {
  ...MasterModule,
  ...LoginModule,
  ...UserManagementModule,
  ...ProjectManagementModule,
  ...TaskManagementModule,
  ...ThemeModule,
  ...WorkspaceModule,
  ...StatisticsCaptureModule,
  ...DashboardModule,
}
export default api
