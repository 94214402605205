import request from '../axiosInterceptor'

const api = {
  getWorkspace() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/auth/workspaces`,
      method: 'get',
    })
  },
  getAfterAuthWorkspace() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/auth/workspaces-access-token`,
      method: 'get',
    })
  },
  postSelectWorkspace(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/auth/select-workspace`,
      method: 'post',
      data: data,
    })
  },
  postChangeWorkspace(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/auth/change-workspace`,
      method: 'post',
      data: data,
    })
  },
}

export default api
