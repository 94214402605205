import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the shape of your context
interface ProjectContextType {
  projectPermission: any
  setProjectPermission: any
}

// Create the context with a default value
const ProjectContext = createContext<ProjectContextType | undefined>(undefined)

// Create the provider component
const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const [projectPermission, setProjectPermission] = useState<any>(null)

  const toggleTheme = (data: any) => {
    setProjectPermission(data)
  }

  return <ProjectContext.Provider value={{ projectPermission, setProjectPermission }}>{children}</ProjectContext.Provider>
}

// Custom hook for using the ThemeContext
const useProjectPermission = () => {
  const context = useContext(ProjectContext)
  if (context === undefined) {
    throw new Error('useProject must be used within a ProjectProvider')
  }
  return context
}

export { ProjectProvider, useProjectPermission }
