// cookieHelper.js

import { trackPromise } from 'react-promise-tracker'
import { clearLocalStorage, getLocalStorage, isSuccess, showError } from '../utils/helper'
import api from '../api'

// Function to get cookie value by name
export const getCookie = (name: any) => {
  const cookies = document.cookie.split('; ')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=')
    if (cookie[0] === name) {
      return cookie[1]
    }
  }
  return null
}
// Function to set cookie value
export const setCookie = (name: string, value: any, options?: any) => {
  let cookieString = `${name}=${value}`
  if (options) {
    if (options.expires) {
      const expires = new Date(options.expires).toUTCString()
      cookieString += `; expires=${expires}`
    }
    if (options.path) {
      cookieString += `; path=${options.path}`
    }
    if (options.domain) {
      cookieString += `; domain=${options.domain}`
    }
    if (options.secure) {
      cookieString += `; secure`
    }
    if (options.sameSite) {
      cookieString += `; SameSite=${options.sameSite}`
    }
  }
  document.cookie = cookieString
}

// Function to remove cookie by name
export const removeCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export const isAuthenticated = () => {
  return getLocalStorage('access_token')
  // return getCookie("access_token")
}

export const logout = () => {
  trackPromise(
    api
      .postLogout()
      .then((response: any) => {
        // @TODO: till api not working
        if (isSuccess(response) || true) {
          clearLocalStorage()
          // localStorage.clear()
          let cookies = document.cookie.split(';')
          cookies.forEach(function (cookie) {
            let cookieParts = cookie.split('=')
            let cookieName = cookieParts[0].trim()
            removeCookie(cookieName)
          })
          window.location.href = '/'
        }
      })
      .catch((error: any) => {
        showError(error)
      }),
  )
}
