import request from '../axiosInterceptor'

const api = {
  getThemeFromId(domain: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/auth/get-theme?subsidiary_master_id=${domain}`,
      method: 'get',
    })
  },
}

export default api
