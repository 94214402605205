import React, { useEffect } from 'react'
import { isSuccess, setLocalStorage, showError } from '../../../utils/helper'
import { trackPromise } from 'react-promise-tracker'
import api from '../../../api'

const GetTheme = () => {
  const urlParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    const domain = urlParams.get('theme_token')
    if (domain) {
      trackPromise(
        api
          .getThemeFromId(domain)
          .then((response: any) => {
            if (isSuccess(response)) {
              const details = response?.data?.data as any
              setLocalStorage('theme', typeof details?.theme_json === 'string' ? details?.theme_json : JSON.stringify(details?.theme_json))
              setLocalStorage('logo', details?.theme_json?.subsidiary_logo_url)
              urlParams.delete('theme_token')
              const newUrl = `${window.location.pathname}?${urlParams.toString()}`
              window.history.replaceState(null, '', newUrl)
              window.location.reload()
            }
          })
          .catch((error: any) => {
            showError(error)
          }),
      )
    }
  }, [urlParams.get('domain')])

  return null
}

export default GetTheme
