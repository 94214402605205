import request from '../axiosInterceptor'

const api = {
  getObservationDashboard() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/dashboard/observations-list`,
      method: 'get',
    })
  },
  getIncidentDashboard(query: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/dashboard/incidents-list${query}`,
      method: 'get',
    })
  },
  getInspectionDashboard(query: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/dashboard/inspection-list${query}`,
      method: 'get',
    })
  },
}

export default api
