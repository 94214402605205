import request from '../axiosInterceptor'

const api = {
  postLogin(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/auth/login`,
      method: 'post',
      data: data,
    })
  },
  postOTPVerify(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/auth/validate-otp`,
      method: 'post',
      data: data,
    })
  },
  postRefreshToken() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/auth/refresh-token`,
      method: 'post',
    })
  },
  postLogout() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/auth/logout`,
      method: 'post',
    })
  },
}

export default api
