import BigLogoNai from './logos/bigLogo.png'
import SmallLogo from './logos/smallLogo.png'
import BellIcon from './icons/bell.svg'
import SortIcon from './icons/sort.svg'
import HamburgerIcon from './icons/hamburger.svg'
import EyeIcon from './icons/eye.svg'
import PreviewImage from './other/preview.jpg'
import LoaderLogo from './icons/loaderLogo.svg'
import CheckboxRounded from './icons/checkboxRounded.svg'
import CheckboxRoundedUnselected from './icons/checkboxRoundedUnselected.svg'
import CheckboxSquare from './icons/checkboxSquare.svg'
import CheckboxSquareUnselect from './icons/checkboxSquareUnselect.svg'
import { getLocalStorage } from '../../utils/helper'

const BigLogo = getLocalStorage('logo') || BigLogoNai
// const BigLogo = BigLogoNai
export {
  BigLogo,
  SmallLogo,
  BellIcon,
  SortIcon,
  HamburgerIcon,
  EyeIcon,
  PreviewImage,
  LoaderLogo,
  CheckboxRounded,
  CheckboxRoundedUnselected,
  CheckboxSquare,
  CheckboxSquareUnselect,
}
