import axios from 'axios'
import { conflict, unauthorized } from './statusCodes'
import toast from 'react-hot-toast'
import { getLocalStorage, isSuccess, setLocalStorage } from '../utils/helper'
import { logout } from '../hooks/CookiesHook'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  timeout: 60000,
})

api.interceptors.response.use(
  (response: any) => {
    if (response?.data?.meta?.code === conflict) {
      logout()
    }
    if (response?.data?.meta?.code === unauthorized) {
      // Refresh token api
      axios
        .post(
          `${process.env.REACT_APP_API_BASEURL}v1/auth/refresh-token`,
          {},
          {
            headers: {
              'access-token': `${getLocalStorage('access_token')}`,
              'refresh-token': `${getLocalStorage('refresh_token')}`,
            },
          },
        )
        .then((res: any) => {
          if (isSuccess(res) && res?.data?.meta?.access_token) {
            setLocalStorage('access_token', res?.data?.meta?.access_token)
            // window.location.reload()
          } else {
            logout()
          }
        })
        .catch((error: any) => {
          toast.error(error.response.data.message)
          logout()
        })
    }
    return response
  },
  function (error) {
    if (error.response) {
      if (error.response?.status === unauthorized) {
        logout()
        // Refresh token api
        // axios
        //   .post(
        //     `${process.env.REACT_APP_API_BASEURL}v1/asgard/refresh-token`,
        //     {},
        //     {
        //       headers: {
        //         'access-token': `${getLocalStorage('access_token')}`,
        //         'refresh-token': `${getLocalStorage('refresh_token')}`,
        //       },
        //     },
        //   )
        //   .then((res: any) => {
        //     setLocalStorage('access_token', res?.data?.data?.access_token)
        //     window.location.reload()
        //   })
        //   .catch((error: any) => {
        //     toast.error(error.response.data.message)
        //   })
      }
    }
    return Promise.reject(error.response)
  },
)

api.interceptors.request.use(
  (config: any) => {
    config.headers = {
      'access-token': `${getLocalStorage('access_token')}`,
    }

    if (config?.url?.includes('auth/workspaces') || config?.url?.includes('select-workspace') || config?.url?.includes('change-workspace')) {
      config.headers = {
        'temp-token': `${getLocalStorage('temp_token')}`,
        'access-token': `${getLocalStorage('access_token')}`,
        'refresh-token': `${getLocalStorage('refresh_token')}`,
      }
    }

    if (config?.url?.includes('auth/logout')) {
      config.headers = {
        'access-token': `${getLocalStorage('access_token')}`,
        'refresh-token': `${getLocalStorage('refresh_token')}`,
        temp_token: `${getLocalStorage('temp_token')}`,
      }
    }

    return config
  },
  function (error: any) {
    return Promise.reject(error)
  },
)

export default api
