
import { createTheme } from '@mui/material/styles' 
import { red } from '@mui/material/colors'
import { getLocalStorage } from './utils/helper';

const localStorageTheme = getLocalStorage('theme') ? JSON.parse(getLocalStorage('theme') || '') : null as any
const theme = createTheme({
  palette: {
    primary: {
      main: (localStorageTheme && localStorageTheme?.colors && localStorageTheme?.colors?.primary) ? localStorageTheme?.colors?.primary  : '#456DB3',
    },
    secondary: {
      main: (localStorageTheme && localStorageTheme?.colors && localStorageTheme?.colors?.primary) ? localStorageTheme?.colors?.secondary  : '#ecf0f7',
    },
    error: {
      main: red.A400,
    },
    common: {
      black: '#000',
    },
  },
}) as any

export default theme